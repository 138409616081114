import React from 'react';
import { Link } from 'react-router-dom';

type Bread = {
    title?: string;
    subtitle?: string;
}

const BreadCrumb: React.FC<{ bread?: Bread }> = ({ bread }) => {
    return (
        <>
            {/* [ breadcrumb ] start */}
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">ADMIN</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/dashboard"}>
                                        {bread?.title}
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/dashboard"}>
                                        {bread?.subtitle}
                                    </Link>
                                </li>
                                <li className="breadcrumb-item" aria-current="page"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* [ breadcrumb ] end */}
        </>
    );
}

export default BreadCrumb;
