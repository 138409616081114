import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { getDateFormat } from '../../helpers/utils';
import ProcessDevisModal from '../../components/modals/ProcessDevisModal';

const DevisPage = () => {

    const snap = useSnapshot(store);

    const bread = {
        title: "Etats des paiements",
        subtitle: "Liste des paiements"
    }

    const fetchDevis = async () => {
        await store.core.getDevis();
        if (snap.core.devis.length > 0) {
            console.log("devis", snap.core.devis);
        }
    }

    function getStatus(status: string) {
        let text = "";
        switch (status) {
            case 'pending':
                text = "En attente"
                break;
            case 'processing':
                text = "En cours"
                break;
            case 'completed':
                text = "Terminer"
                break;
            default:
                text = ""
                break;
        }
        return text
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDevis();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Gestion des demandes de devis</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Service</th>
                                            <th>Nom</th>
                                            <th>Email</th>
                                            <th>Contact</th>
                                            <th>Pays</th>
                                            <th>Status</th>
                                            <th>Date Devis</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.devis.map((devis,index) => (
                                            <tr key={devis.id}>
                                                <td>{index+1}</td>
                                                <td>{devis.libelle}</td>
                                                <td>{devis.customer?.nom} {devis.customer?.prenoms} </td>
                                                <td>{devis.customer?.email}</td>
                                                <td>{devis.customer?.phone}</td>
                                                <td>{devis.country}</td>
                                                <td>
                                                    {getStatus(devis.status)}
                                                </td>
                                                <td>{getDateFormat(String(devis.created_at))}</td>
                                                <td>
                                                    {devis.status === 'pending' ? (
                                                        <ProcessDevisModal devis={devis} index={devis.id} />
                                                    ):(
                                                        <button className='btn btn-sm btn-success'>Voir le détail</button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DevisPage
