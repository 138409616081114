/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { getDateFormat } from '../../helpers/utils';

const SubscriptionPage = () => {

    const snap = useSnapshot(store);
    const [loading, setLoading] = useState(true);

    const bread = {
        title: "Etats des abonnements",
        subtitle: "Liste"
    }

    function getStatus(status: string) {
        let text = "";
        switch (status) {
            case 'pending':
                text = "En attente"
                break;
            case 'active':
                text = "Activé"
                break;
            case 'expired':
                text = "Expirée"
                break;
            default:
                text = ""
                break;
        }
        return text
    }

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const res = await store.core.getSubscriptions();
            if (res.success) {
                setLoading(false); // Mettre à jour loading après le chargement initial
            }
        };
    
        fetchSubscriptions(); // Appel initial
    
        // Nettoyer l'effet
        return () => {
            // Mettre à jour loading à false lors du démontage du composant
            setLoading(false);
        };
    }, []); // Uniquement exécuté au montage
    

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES SOUSCRIPTIONS</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Client</th>
                                            <th>Plan</th>
                                            <th>Prix</th>
                                            <th>Date Debut</th>
                                            <th>Date Fin</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.subscriptions.map((subscribe, index) => (
                                            <tr key={subscribe.id}>
                                                <td>{index + 1}</td>
                                                <td>{subscribe.client?.nom} {subscribe.client?.prenoms} </td>
                                                <td>{subscribe.plan?.name}</td>
                                                <td>{subscribe.price}</td>
                                                <td>{subscribe.start_date}</td>
                                                <td>{subscribe.end_date}</td>
                                                <td>
                                                    {getStatus(subscribe.status)}
                                                </td>
                                                <td>{getDateFormat(String(subscribe.created_at))}</td>
                                                <td>
                                                    
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPage
