import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { getDateFormat } from '../../helpers/utils';

const PaymentPage = () => {

    const snap = useSnapshot(store);
    const [loading, setLoading] = useState(true);
    const bread = {
        title: "Etats des paiements",
        subtitle: "Liste des paiements"
    }

    const fetchTransactions = async () => {
        const res = await store.core.getTransactions();
        if (res.success) {
            setLoading(false);
        }
    }

    function getStatus(status: string) {
        let text = "";
        switch (status) {
            case 'success':
                text = "Réussi"
                break;
            case 'active':
                text = "Echoué"
                break;
            case 'cancelled':
                text = "Annulée"
                break;
            default:
                text = ""
                break;
        }
        return text
    }

    useEffect(() => {
        const getData = async () => {
            await fetchTransactions();
        }
        if (loading) {
            getData()
        }
    }, [loading]);

    return (
        <div>
            <BreadCrumb bread={bread}  />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">ETATS DES PAIEMENTS</h5>
                        </div>
                        <div className="card-body">
                        <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Client</th>
                                            <th>Plan</th>
                                            <th>Prix</th>
                                            <th>Date Paiement</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.transactions?.map((transact, index) => (
                                            <tr key={transact.id}>
                                                <td>{index + 1}</td>
                                                <td>{transact.user?.name}</td>
                                                <td>{transact.plan?.name}</td>
                                                <td>{transact.amount}</td>
                                                <td>{transact.paid_at}</td>
                                                <td>{transact.description}</td>
                                                <td>
                                                    {getStatus(transact.status)}
                                                </td>
                                                <td>{getDateFormat(String(transact.created_at))}</td>
                                                <td>
                                                    
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentPage
