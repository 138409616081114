/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { Client } from '../../models';
const DetailClientPage = () => {

    const client_id = Number(useParams().client_id)
    const snap = useSnapshot(store);
    const [client, setClient] = useState({} as Client);
    const [tab,setTab] = useState("details");

    const fetchClient = async () => {
        let clients = snap.user.clients;
        const clt = clients.find((client) => client.id === client_id);
        if (clt !== undefined) {
            console.log("client found", clt);
            
            setClient(clt);
        } else {
            console.log("client not found");
            // history.back();
        }
    }

    const changeTab = (tab: string) => {
        console.log("tab selected",tab);
        
        setTab(tab);
    }

    useEffect(() => {
        console.log("client_id", client_id);
        fetchClient();
    }, [])

    return (
        <>
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Détails du compte: {client.nom} {client.prenoms} </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <ul className="nav flex-column nav-tabs account-tabs mb-3" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-icons-two-tone me-2">person_outline</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h5 className="mb-0">Infos générales</h5>
                                                        <small className="text-muted">Informations génrales</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="false" tabIndex={-1}>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-icons-two-tone me-2">description</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h5 className="mb-0">Plan</h5>
                                                        <small className="text-muted">Plan d'abonnement</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="false" tabIndex={-1}>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-icons-two-tone me-2">credit_card</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h5 className="mb-0">Services</h5>
                                                        <small className="text-muted">Services payé</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="false" tabIndex={-1}>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-icons-two-tone me-2">vpn_key</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h5 className="mb-0">Sécurité</h5>
                                                        <small className="text-muted">Modifier les paramètres du compte</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-8">
                                    <div className="tab-content">
                                        <div className="tab-pane active show" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0">
                                                    <img src="/assets/images/user/avatar-5.jpg" alt="user-image" className="img-fluid rounded-circle wid-80" />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <small className="text-muted"><i className="material-icons-two-tone f-14">info</i> Image size Limit should be 125kb Max.</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Nom</label>
                                                        <input type="text" className="form-control" defaultValue={client?.nom} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Prénoms</label>
                                                        <input type="text" className="form-control" defaultValue={client?.prenoms} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Email Address</label>
                                                        <input type="email" className="form-control" defaultValue="demo@company.com" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Phone Number</label>
                                                        <input type="text" className="form-control" defaultValue="000-00-00000" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Company Name</label>
                                                        <input type="text" className="form-control" defaultValue="company.ltd" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Site Information</label>
                                                        <input type="text" className="form-control" defaultValue="www.company.com" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6"> </div>
                                                <div className="col-6 text-end">
                                                    <button className="btn btn-primary d-inline-flex align-items-center" onClick={() => changeTab('#profile-2')} >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Block No#</label>
                                                        <input type="text" className="form-control" defaultValue={16657} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Apartment Name</label>
                                                        <input type="text" className="form-control" defaultValue=" Dardan Ranch" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Street Line 1</label>
                                                        <input type="text" className="form-control" defaultValue="Nathaniel Ports" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Street Line 2</label>
                                                        <input type="text" className="form-control" defaultValue="nr. Oran Walks" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Postcode</label>
                                                        <input type="text" className="form-control" defaultValue={395005} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Select City</label>
                                                        <select className="form-select">
                                                            <option>Los Angeles</option>
                                                            <option>Chicago</option>
                                                            <option>Phoenix</option>
                                                            <option>San Antonio</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Select Country</label>
                                                        <select className="form-select">
                                                            <option>India</option>
                                                            <option>Chicago</option>
                                                            <option>Phoenix</option>
                                                            <option>San Antonio</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultValue={1} id="flexCheckDefault" defaultChecked />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault"> Same as billing address </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6">
                                                    <button className="btn btn-outline-secondary d-inline-flex align-items-center" onClick={() => changeTab('#profile-1')}>Back</button>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <button className="btn btn-primary d-inline-flex align-items-center" onClick={() => changeTab('#profile-3')}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-3">
                                            <div className="row">
                                                <div className="col-sm-12 mb-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked />
                                                        <label className="form-check-label" htmlFor="inlineRadio1">Visa Credit/Debit Card</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                                                        <label className="form-check-label" htmlFor="inlineRadio2">PayPal</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Name on Card</label>
                                                        <input type="text" className="form-control" defaultValue="Selena Litten" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Card Number</label>
                                                        <input type="text" className="form-control" defaultValue="4012 8888 8888 1881" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Expiry Date</label>
                                                        <input type="text" className="form-control" defaultValue="10/22" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">CCV Code</label>
                                                        <input type="text" className="form-control" defaultValue={123} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="flex-shrink-0">
                                                            <i className="material-icons-two-tone me-2 f-50 text-primary">lock</i>
                                                        </div>
                                                        <div className="flex-grow-1 ms-2">
                                                            <h4 className="mb-0">Secure Checkout</h4>
                                                            <span className="text-muted">Secure by Google.</span>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-outline-primary"><i className="ti ti-credit-card text-top" /> Add New card</button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6">
                                                    <button className="btn btn-outline-secondary d-inline-flex align-items-center" onClick={() => changeTab('#profile-3')}>Back</button>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <button className="btn btn-primary d-inline-flex align-items-center" onClick={() => changeTab('#profile-4')}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Current Password <span className="text-danger">*</span></label>
                                                        <input type="password" className="form-control" placeholder="Enter Your current password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">New Password <span className="text-danger">*</span></label>
                                                        <input type="password" className="form-control" placeholder="Enter New password" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                                        <input type="password" className="form-control" placeholder="Enter your password again" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button className="btn btn-outline-primary">Change Password</button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6">
                                                    <button className="btn btn-outline-secondary d-inline-flex align-items-center" onClick={() => changeTab('#profile-3')}>Back</button>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <button className="btn btn-primary d-inline-flex align-items-center">Submit<i className="ti ti-chevron-right ms-2" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* [ sample-page ] end */}
            </div>

        </>
    )
}

export default DetailClientPage
