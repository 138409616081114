/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from 'react-toastify';

type RegisterClient = {
    nom: string;
    prenoms: string;
    email: string;
    phone: string;
    countryId: number;
    address?: string;
    profession?: string;
    gender?: string;
    situation_matrimoniale?: string;
    create_user?: boolean;
}

const AddClientModal = () => {

    const snap = useSnapshot(store);
    const [isLoaded, setIsLoaded] = useState(false);
    const [prefix, setPrefix] = useState("");
    const [countryId,setCountryId] = useState(0);
    const { register, handleSubmit, formState: { errors } } = useForm<RegisterClient>();


    const handleCountryChange = (event: any) => {
        const selectedCountryId = Number(event.target.value);
        console.log("selectedCountryId", selectedCountryId);
        const selectedCountry = snap.core.getCountryById(selectedCountryId);
        console.log("country",selectedCountry);
        if (selectedCountry) {
            console.log('============selected country========================');
            console.log(selectedCountry);
            console.log('====================================');
            setPrefix(selectedCountry.prefix);
            setCountryId(selectedCountryId);
        } else {
            console.log("not found");
            
            setPrefix("");
        }
    };

    const onSubmit: SubmitHandler<RegisterClient> = async (data) => {
        let btn_signup = document.getElementById('btn_signup') as HTMLButtonElement;
        btn_signup.disabled = true;
        btn_signup.innerText = "Traitement en cours...";
        try {
            const {addClient} = store.user;
            data.countryId = countryId;
            console.log(data);
            const res = await addClient(data);
            console.log("api res", res);

            if (res.success) {
                toast.success(res.message);
                //reset form
                data = {
                    nom: '',
                    prenoms: '',
                    email: '',
                    phone: '',
                    countryId: '' as any,
                    profession: '',
                    gender: '',
                    situation_matrimoniale: ''
                }
            } else {
                toast.error(res.message);
                setTimeout(() => {
                    btn_signup?.removeAttribute('disabled');
                }, 3500);
                btn_signup.innerText = 'Envoyer';
            }
        } catch (error) {
            console.log("error occurred",error);
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_signup?.removeAttribute('disabled');
            }, 3500);
            btn_signup.innerText = 'Envoyer';
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                store.core.getCountries(),
                // Ajoutez d'autres appels de fonction pour récupérer d'autres données de l'API ici
            ]);
            setIsLoaded(true);
        };

        if (!isLoaded) {
            fetchData();
        }

        return () => {
            // Code de nettoyage si nécessaire
        };
    }, [isLoaded]);

    return (
        <>
            <button className="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_add_contact">
                <i className="ti ti-circle-plus align-text-bottom" /> Nouveau Client
            </button>
            {/* [ Main Content ] end */}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvas_add_contact">
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit(onSubmit)} method="post">
                        <h4 className="my-3">Ajouter un nouveau client</h4>
                        <hr className="my-3" />
                        <div className="form-group">
                            <label className="form-label">Nom</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-two-tone">account_circle</i></span>
                                <input type="text" className="form-control" placeholder="Entrez le nom du client ici" {...register("nom",{required: true})} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Prénoms</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-two-tone">account_circle</i></span>
                                <input type="text" className="form-control" placeholder="Entrer le prénoms du client" {...register("prenoms",{required: true})} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label">Profession</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-two-tone">work</i></span>
                                <input type="text" className="form-control" placeholder="Entrez la profession du client" {...register("profession",{required: false})} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-two-tone">email</i></span>
                                <input type="text" className="form-control" placeholder="Saisir l'adresse Email du client" {...register("email",{required: true})} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label">Téléphone</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-two-tone">call</i></span>
                                <input type="text" className="form-control" placeholder="Entrer le numéro de téléphone du client" {...register("phone",{required: false})} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Pays</label>
                            <div className="input-group">
                                <span className="input-group-text">{prefix}</span>
                                <select className="form-control" name="country_id" id="country_id" onChange={(event)=> handleCountryChange(event)}>
                                    <option value="" defaultValue={''}>Choisir un pays</option>
                                    {snap.core.countries.map((country) => (
                                        <option key={country.id} value={country.id}>{country.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Ville</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-two-tone">location_on</i></span>
                                <input type="text" className="form-control" placeholder="Entrer la ville du client" {...register("address",{required: false})} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Civilité</label>
                            <select className="form-control" id="gender" {...register("gender",{required: true})} >
                                <option value="MASCULIN" defaultValue={'MASCULIN'}>MASCULIN</option>
                                <option value="FEMININ">FEMININ</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Situation Matrimoniale</label>
                            <select className="form-control" id="situation_matrimoniale" {...register("situation_matrimoniale",{required: true})} >
                                <option value="Célibataire" defaultValue={'Célibataire'}>Célibataire</option>
                                <option value="Marié(e)">Marié(e)</option>
                                <option value="Divorce(e)">Divorce(e)</option>
                                <option value="Veuf(ve)">Veuf(ve)</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input input-primary" type="checkbox" defaultChecked id="customCheckc1" {...register("create_user",{required: false})} />
                                <label className="form-check-label text-muted" htmlFor="customCheckc1">Créer un compte utilisateur</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6"><div className="d-grid"><button type='submit' id='btn_signup' className="btn btn-primary">Ajouter</button></div></div>
                            <div className="col-6"><div className="d-grid"><button className="btn btn-outline-primary" data-bs-dismiss="offcanvas">Fermer</button></div></div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

export default AddClientModal
