

function setCookie(c_name: string, c_value: string) {
    var expires = getExpires();
    document.cookie = c_name + "=" + c_value + ";" + expires + ";path=/";
}

/**
 * getCookie function
 * Permet de retourner la valeur d'un cookie
 * @param {string} cname
 * @returns value
 */
function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function getToken(): string | null {
    // @ts-ignore
    const token = JSON.parse(localStorage.getItem('token'));
    if (typeof token === 'string') {
        return token;
    } else {
        return null;
    }
}

function deleteToken() {
    // @ts-ignore
    const expire_date = JSON.parse(localStorage.getItem('tokenExpiration')) as string;
    const tokenExpiration = convertToTime(expire_date);
    let is_auth = true;
    const now = new Date().getTime();
    if (now - tokenExpiration >= 1 * 60 * 60 * 1000) {
        localStorage.clear();
        console.log('Le jeton d\'authentification a été supprimé.');
        is_auth = false;
    }
    return is_auth;
}

function convertToTime(dateString: string) {
    const dateObj = new Date(dateString);
    return dateObj.getTime();
}

function getExpires() {
    var d = new Date();

    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    return expires;
}

/**
 *deleteCookie function
 * Permet de supprimer un cookie
 * @param {string} cname
 */
function deleteCookie(cname: string) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function getDateFormat(dateString: string): string {
    // Convertir la chaîne de date en objet Date
    const date = new Date(dateString);
    // Extraire le jour, le mois et l'année
    const day = date.getDate();
    const month = date.getMonth() + 1; // Les mois commencent à 0, donc nous ajoutons 1
    const year = date.getFullYear();

    // Formater la date au format JJ/MM/AAAA
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
}

const random = (length = 8) => {
    // Declare all characters
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // Pick characers randomly
    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
};

const checkAuthUser = () => {
    let token = getToken() ? getToken() : getCookie('token');
    let is_auth = true;
    if (token) {
        // @ts-ignore
        const expire_date = localStorage.getItem('tokenExpiration') as string;
        const tokenExpiration = convertToTime(expire_date);
        const now = new Date().getTime();
        if (now - tokenExpiration >= 1 * 60 * 60 * 1000) {
            localStorage.clear();
            console.log('Le jeton d\'authentification a été supprimé.');
            is_auth = false;
        }
    }else{
        console.log("token not found");
        is_auth = false;
    }
    return is_auth;
}

function getStatus(status: string) {
    let text = "";
    switch (status) {
        case 'pending':
            text = "En attente"
            break;
        case 'processing':
            text = "En cours"
            break;
        case 'completed':
            text = "Terminer"
            break;
        default:
            text = ""
            break;
    }
    return text
}

export {
    setCookie, getCookie, getExpires, deleteCookie, random, getToken, deleteToken,
    convertToTime, getDateFormat, checkAuthUser,getStatus
};
