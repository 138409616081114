import { proxy } from 'valtio';
import type { User, Response, } from '../../models';
import { postData, postDataWithToken } from '../../helpers/http';
import { setCookie } from '../../helpers/utils';

class AuthStore {
    isAuthenticated: boolean = false;
    user: User = {} as User;
    token: string = '';

    constructor() {
        // Récupérer l'état initial du localStorage lors de la création de l'instance
        const storedState = localStorage.getItem('authStoreState');
        if (storedState) {
            const initialState = JSON.parse(storedState);
            this.isAuthenticated = initialState.isAuthenticated;
            this.user = initialState.user;
            this.token = initialState.token;
        }

        // Assurer que les méthodes sont liées à l'instance actuelle
        this.setAuthState = this.setAuthState.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    private setAuthState(user: User, token: string): void {
        this.isAuthenticated = true;
        this.user = user;
        this.token = token;

        // Mettre à jour localStorage lors du changement d'état
        localStorage.setItem('authStoreState', JSON.stringify(this));
    }

    async login(payload: any): Promise<Response> {
        try {
            const response = await postData('auth/login', payload);
            const result = response.result;
            console.log("api response", response);

            if (response.success) {
                if (result.user.role_id !== 2) {
                    localStorage.setItem('tokenExpiration', result.expired_at);
                    document.cookie = 'token=' + result.token + '; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    setCookie('token',result.token)
                    this.setAuthState(result.user, result.token);
                } else {
                    return {
                        success: false,
                        message: "Vous n'êtes pas autorisé à vous connecter à cet espace.",
                        result: null,
                    }
                }
            }
            return response;
        } catch (error) {
            console.log("api error", error);

            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async logout(): Promise<Response> {
        try {
            const response = await postDataWithToken('auth/logout');
            if (response.success) {
                this.isAuthenticated = false;
                this.user = {} as User;
                this.token = '';

                // Supprimer l'état du localStorage lors de la déconnexion
                localStorage.clear()
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }


}

const authStore = proxy(new AuthStore());

export default authStore;
