/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState} from 'react';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { getDateFormat, getStatus } from '../../helpers/utils';
import { Link } from 'react-router-dom';

const Dashboard = () => {

    const snap = useSnapshot(store);
    const [loading, setLoading] = useState(true);
    const [totalDevisPaid, setTotalDevisPaid] = useState(0);
    const [totalClient, setTotalClient] = useState(0);
    const [totalClientSubscribe, setTotalClientSubscribe] = useState(0);


    const fetchData = async()=>{
        const res = await store.core.getAnalyticData();
        console.log("api response",res);
        if (res.success) {
            let clients = res.result.clients;
            let devis = res.result.devis.slice(0,5);
            let subscriptions = res.result.subscriptions;
            setTotalClient(clients.length);
            getTotalDevisPaid();
            setTotalClientSubscribe(subscriptions?.length);
            setLoading(false);
        }
    }

    const getTotalDevisPaid = ()=>{
        let devis = snap.core.devis;
        let totalPrice = 0;
        if (devis.length > 0) {
            devis.forEach(element => {
                if (element.paid_at !== null && element.status === "completed" ) {
                    totalPrice += Number(element.price);
                }
            });
            setTotalDevisPaid(totalPrice);
        }
    }

    const getDevis = ()=> {
        let devis = snap.core.devis.filter((devis)=>devis.status === "pending");
        return devis.slice(0,5);
    }

    useEffect(() => {
        const getData = async () => {
            await fetchData();
        }
        if (loading) {
            getData();
        }
    }, []);

    return (
        <>
            {/* [ Main Content ] start */}
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-xl-4 col-md-6">
                    <div className="card bg-warning dashnum-card text-white overflow-hidden">
                        <span className="round small" />
                        <span className="round big" />
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="avtar avtar-lg">
                                        <i className="text-white ti ti-credit-card" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    
                                </div>
                            </div>
                            <span className="text-white d-block f-34 f-w-500 my-2">{totalClient}<i className="fas fa-user-circle opacity-50" /></span>
                            <p className="mb-0 opacity-30">Clients inscrits</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="card bg-dark dashnum-card text-white overflow-hidden">
                        <span className="round small" />
                        <span className="round big" />
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="avtar avtar-lg">
                                        <i className="text-white ti ti-credit-card" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    
                                </div>
                            </div>
                            <span className="text-white d-block f-34 f-w-500 my-2">{totalClientSubscribe} <i className="ti ti-arrow-up-right-circle opacity-50" /></span>
                            <p className="mb-0 opacity-50">Total clients souscrits</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12">
                    <div className="card bg-dark dashnum-card dashnum-card-small text-white overflow-hidden">
                        <span className="round bg-primary small" />
                        <span className="round bg-primary big" />
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                                <div className="avtar avtar-lg">
                                    <i className="text-white ti ti-credit-card" />
                                </div>
                                <div className="ms-2">
                                    <h4 className="text-white mb-1">{totalDevisPaid} € <i className="ti ti-arrow-up-right-circle opacity-50" /></h4>
                                    <p className="mb-0 opacity-50 text-sm">Total Devis Payé</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card dashnum-card dashnum-card-small overflow-hidden">
                        <span className="round bg-warning small" />
                        <span className="round bg-warning big" />
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                                <div className="avtar avtar-lg bg-light-warning">
                                    <i className="text-warning ti ti-credit-card" />
                                </div>
                                <div className="ms-2">
                                    <h4 className="mb-1">550K € <i className="ti ti-arrow-up-right-circle opacity-50" /></h4>
                                    <p className="mb-0 opacity-50 text-sm">Balance Totale</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <h4>Historiques des transactions</h4>
                                    <small>Montant Total</small>
                                    <h3>$2,324.00</h3>
                                </div>
                                <div className="col-auto">
                                    <select className="form-select p-r-35">
                                        <option>Aujourd'hui</option>
                                        <option selected>Ce mois</option>
                                        <option>Cette année</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <h4>Dernière demande de devis</h4>
                                </div>
                                <div className="col-auto"> </div>
                            </div>
                            <div className="rounded bg-light-secondary overflow-hidden mb-3">
                                <div className="px-3 pt-3">
                                </div>
                            </div>
                            <ul className="list-group list-group-flush">
                                {getDevis()?.map((item, index) => (
                                    <li className="list-group-item px-0" key={index}>
                                        <div className="row align-items-start">
                                            <div className="col">
                                                <h5 className="mb-0">{item.libelle}</h5>
                                                <small className="text-success">{item.customer?.nom} {item.customer?.prenoms} </small>
                                                <small className="text-success">{getDateFormat(String(item.created_at))} </small>
                                            </div>
                                            <div className="col-auto">
                                                <h4 className="mb-0">{getStatus(item.status)}<span className="ms-2 align-top avtar avtar-xxs bg-light-success"><i className="ti ti-chevron-up text-success" /></span></h4>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="text-center">
                                <Link to="/dashboard/devis" className="b-b-primary text-primary">
                                    Voir tout <i className="ti ti-chevron-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* [ sample-page ] end */}
            </div>
            {/* [ Main Content ] end */}

        </>
    )
}

export default Dashboard
